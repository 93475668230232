.toggle-button {
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	margin: 0;
	padding: 4.8px 7px;
	background-color: var(--branco);
	border: 1px solid #ced4da;
	color: var(--cinzaText);
	border-radius: 3px;
	width: 32px;
	height: 32px;
	transition:
		background-color 0.2s,
		color 0.2s,
		border-color 0.2s,
		box-shadow 0.2s;
}

input.toggle-button:hover,
input.toggle-button:focus {
	outline: none;
	border: 1px solid var(--principal) !important;
}

.toggle-button.p-highlight {
	background-color: var(--principalHoverTransparente) !important;
	color: var(--principal) !important;
	border: 1px solid var(--principal) !important;
}

.divider-group {
	display: flex;
	align-items: flex-end;
	column-gap: 4px;
	margin-right: 16px;
}
