.grid-Produtos::-webkit-scrollbar {
	width: 0.4rem;
}

.grid-Produtos::-webkit-scrollbar-track {
	background: none;
}

.grid-Produtos::-webkit-scrollbar-thumb {
	background-color: rgba(177, 177, 177, 0.74);
	border-radius: 20px;
	border: none;
}

.message-produto-venda-effect {
	animation: animate 1s ease-in-out;
}

@keyframes animate {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.015);
	}
	100% {
		transform: scale(1);
	}
}

.tab-view-plano .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--principal) !important;
	border-bottom: 5px solid var(--principal) !important;
	margin-bottom: -1px;
}

.tab-view-plano .p-tabview-nav li {
	background-color: transparent !important;
}

.tab-view-plano .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--principal) !important;
	border-bottom: 5px solid var(--principal) !important;
}

.tab-view-plano .p-tabview-nav li a {
	border: none !important;
	background-color: transparent !important;
}

.tab-view-plano .p-tabview-panels {
	background-color: transparent !important;
	border: 1px solid #c8c8c8;
	padding: 0.571em 1em 0 1em;
}

.tab-view-venda-nota-padrao .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--azul) !important;
	border-bottom: 5px solid var(--azul) !important;
}

.tab-view-venda-nota-finalizada .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--verde) !important;
	border-bottom: 5px solid var(--verde) !important;
}

.tab-view-venda-nota-cancelada .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--vermelho) !important;
	border-bottom: 5px solid var(--vermelho) !important;
}

.tab-view-venda-nota-denegada .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--vermelho) !important;
	border-bottom: 5px solid var(--vermelho) !important;
}

.tab-view-venda-nota-rejeitada .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--amarelo) !important;
	border-bottom: 5px solid var(--amarelo) !important;
}

.tab-view-venda-nota-aguardando .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--cinza) !important;
	border-bottom: 5px solid var(--cinza) !important;
}
