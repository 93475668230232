.content-button-header-listagem-pastas {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	min-height: 50px;
	gap: 6px;
	padding: 0px;
}

@media only screen and (max-width: 768px) {
	.content-button-header-listagem-pastas {
		justify-content: center;
	}
}

@media only screen and (max-width: 576px) {
	.content-button-header-listagem-pastas {
		display: grid;
		grid-template-columns: 1fr 1fr;
		row-gap: 12px;
		column-gap: 6px;
	}

	.content-button-header-listagem-pastas button {
		width: 100%;
	}

	.content-button-header-listagem-pastas span {
		margin: 0px 5px;
	}
}
