.container-servicos-produtos::-webkit-scrollbar {
	width: 0.4rem;
}

.container-servicos-produtos::-webkit-scrollbar-track {
	background: none;
}

.container-servicos-produtos::-webkit-scrollbar-thumb {
	background-color: rgba(177, 177, 177, 0.74);
	border-radius: 20px;
	border: none;
}

.tab-view-os-pendente .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--azul) !important;
	border-bottom: 4px solid var(--azul) !important;
}

.tab-view-os-finalizado .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--verde) !important;
	border-bottom: 4px solid var(--verde) !important;
}

.tab-view-os-cancelado .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--vermelho) !important;
	border-bottom: 4px solid var(--vermelho) !important;
}
