.opa-suite {
	position: fixed;

	z-index: 2;
	right: 8px;
	bottom: 8px;
	width: 48px;
	height: 48px;

	padding: 8px;
	background: #25d366;
	border: none;
	border-radius: 999px;

	box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	-webkit-transition: width 0.4s ease-in-out;
	transition: width 0.4s ease-in-out;

	overflow: hidden;
	white-space: nowrap;
}

.opa-suite:hover {
	width: 148px;
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.opa-suite:focus {
	outline: none;
}

.opa-suite-icon {
	width: 32px;
	height: 32px;
	color: white;
}

.opa-suite-text {
	visibility: hidden;
	color: white;
	position: relative;
	bottom: 10px;
	font-size: 16px;
	margin-left: 6px;
}
.opa-suite:hover .opa-suite-text {
	visibility: visible;
	animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
	from {
		opacity: 0;
		transform: translateX(20px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
