.card {
    margin-bottom: unset;
}

.card h1 {
    margin: 1em 0 .5em !important;
    border-bottom: 1px solid #d5d5d5 !important;
    padding: .1em;
    font-size: 24px;
}

.card.card-w-title {
    padding-bottom: 1em;
}

.card h1:first-child {
    margin: 0 0 .5em 0 !important;
}