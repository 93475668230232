.p-fileupload .p-fileupload-buttonbar {
  border-radius: 12px 12px 0px 0px;
}
.p-fileupload .p-fileupload-content {
  border-radius: 0px 0px 12px 12px;
  overflow-y: scroll;
  max-height: 200px;
  padding: 1rem 0rem;
}

.custom-choose-options-file-upload {
  width: 100%;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: #2e2e2e;
  border-color: #2e2e2e;
}

.p-fileupload .p-fileupload-row > div {
  padding: 0.5rem 1rem;
}

.p-fileupload-files {
  margin: 0px;
}
