.tab-view-categorias .p-tabview-title {
	color: var(--cinzaDark);
}

.tab-view-categorias .p-tabview-nav.p-reset {
	display: flex;
	justify-content: center;
}

.tab-view-categorias .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	border-bottom: 2px solid var(--principal) !important;
	color: var(--principal) !important;
}

.tab-view-categorias .p-tabview-nav li.p-highlight a span.p-tabview-title {
	color: var(--principal);
}

.tab-view-categorias .p-tabview-nav li a {
	border: none !important;
	background-color: transparent !important;
}

.tab-view-categorias .p-tabview-nav li {
	background-color: transparent !important;
}

.tab-view-categorias .p-tabview-panels {
	border: none !important;
	padding: 0px !important;
}
