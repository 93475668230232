.customCol {
  padding: 0;
  margin: -8px 0;
  height: max-content;
}

.customInput {
  margin: 0 0 0 -8px;
  width: 100%;
}

.customDivItems {
  width: 100%;
}

.divider-listagem {
  border: none;
  height: 1px;
  margin: 0 8px 0 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
}