.table-totais-nfse div:nth-child(odd) {
	background-color: rgb(225, 225, 225);
}

.table-totais-nfse div:nth-child(even) {
	background-color: rgb(250, 250, 250);
}

.table-totais-somados {
	background-color: rgb(250, 250, 250) !important;
}

.table-totais-somados span {
	color: #405057;
}

.table-totais-somados div {
	background-color: rgb(250, 250, 250) !important;
}

.tab-view-nfse-pendente .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--azul) !important;
	border-bottom: 4px solid var(--azul) !important;
}
.tab-view-nfse-aguardando .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--cinza) !important;
	border-bottom: 4px solid var(--cinza) !important;
}
.tab-view-nfse-transmitido .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--verde) !important;
	border-bottom: 4px solid var(--verde) !important;
}
.tab-view-nfse-rejeitado .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--amarelo) !important;
	border-bottom: 4px solid var(--amarelo) !important;
}
.tab-view-nfse-cancelado .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--vermelho) !important;
	border-bottom: 4px solid var(--vermelho) !important;
}
