.os-grid-totais {
	margin-top: 0.1rem;
}

.os-grid-totais table {
	color: #000;
}

.os-col-totais:not(:first-child) {
	padding-left: 0;
}

@media (max-width: 768px) {
	.os-col-totais:first-child {
		padding-left: 0;
	}
}

.os-table-totais-impostos {
	width: 100%;
}

.os-table-totais-impostos tr:nth-child(odd) td {
	background-color: rgb(225, 225, 225);
}

.os-table-totais-impostos tr:nth-child(even) td {
	background-color: rgb(250, 250, 250);
}

.os-table-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.2rem;
	font-size: 14px;
}

.os-table-totais {
	width: 100%;
}

.os-table-line-subtotal {
	font-size: 15px;
	font-weight: 600;
	color: #3c3c3c;
	border-top: rgb(190, 190, 190) 1px solid;
	border-bottom: rgb(190, 190, 190) 1px solid;
}

.os-table-line-total {
	font-size: 20px;
	font-weight: bold;
	color: var(--principal);
}

@keyframes labelHover {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.02);
	}
	100% {
		transform: scale(1);
	}
}

.label-totais:hover {
	color: var(--principal);
	font-weight: 500;
	animation-name: labelHover;
	animation-duration: 0.4s;
	animation-timing-function: ease-out;
}
