.grid-titulo-nota-versao::-webkit-scrollbar {
	width: 0.4rem;
}

.grid-titulo-nota-versao::-webkit-scrollbar-track {
	background: none;
}

.grid-titulo-nota-versao::-webkit-scrollbar-thumb {
	background-color: rgba(177, 177, 177, 0.74);
	border-radius: 20px;
	border: none;
}
