/* General */
$fontSize: 14px;
$bodyBgColor: var(--cinzaBackground);
$textColor: var(--cinzaDark);
$textSecondaryColor: #707070;
$borderRadius: 3px;
$dividerColor: #d3d9df;
$transitionDuration: 0.2s;
$maskBgColor: var(--cinza);

/* Menu */
$scrollPanelBgColor: #aaaaaa;
$menuitemBadgeBgColor: var(--cinzaBackground);
$menuitemBadgeColor: var(--branco);
$submenuFontSize: 13px;

/* Menu Dark*/
$menuDarkBgColorFirst: #4d505b;
$menuDarkBgColorLast: #3b3e47;
$menuitemDarkColor: var(--branco);
$menuitemDarkHoverColor: #00192f;
$menuitemDarkActiveColor: #00192f;
$menuitemDarkActiveBgColor: #2e3035;
$menuitemDarkBorderColor: rgba(52, 56, 65, 0.6);

/* Menu Light*/
$menuBgColorFirst: #d3d9df;
$menuBgColorLast: #d3d9df;
$menuitemColor: #232528;
$menuitemHoverColor: var(--principal);
$menuitemActiveColor: #00192f;
$menuitemActiveBgColor: var(--branco);
$menuitemBorderColor: rgba(207, 219, 224, 0.6);

/* Topbar */
$topbarLeftBgColor: #00192f;
$topbarRightBgColor: var(--principal);
$topbarItemBadgeBgColor: var(--branco);
$topbarItemBadgeColor: var(--branco);
$topbarItemColor: var(--branco);
$topbarItemHoverColor: var(--branco);
$topbarSearchInputBorderBottomColor: var(--branco);
$topbarSearchInputColor: var(--branco);

/* Footer */
$footerBgColor: var(--branco);

@import './Sass/_layout';
