#botao-ver-movimentacoes-estoque-produto {
	color: var(--principal);
	font-size: 13px;
	margin-left: 10px;
	cursor: pointer;
}

#botao-ver-movimentacoes-estoque-produto:hover {
	text-decoration: underline;
}
