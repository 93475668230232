.altera-multipliacacao-ou-divisao {
    cursor: pointer;
    background-color: lightgrey;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: -2px;
    width: 45px;
    border-radius: 0 3px 3px 0;
}

.altera-multipliacacao-ou-divisao .altera-multipliacacao-ou-divisao-text {
    display: inline-flex;
}

.altera-multipliacacao-ou-divisao .fa {
    display: none;
}

.altera-multipliacacao-ou-divisao:hover .altera-multipliacacao-ou-divisao-text {
    display: none;
}

.altera-multipliacacao-ou-divisao:hover .fa {
    display: inline-flex;
}

.input-fator-conversao {
  border-radius: 3px 0 0 3px !important;
}