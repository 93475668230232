.filtro-notification {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #ff757573;
    background-color: red;
    position: absolute;
    right: 0px;
    top: 0px;
}

.p-sidebar-close-icon.pi.pi-times{
    margin: 10px;
}