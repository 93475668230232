.table-recebimentos th {
	background-color: transparent !important;
}

.table-recebimentos .p-datatable-tbody .p-datatable-row td {
	border-left: none;
	border-right: none;
}

.table-recebimentos .p-datatable-thead tr th {
	border: none !important;
	text-align: start !important;
}

.table-recebimentos .p-datatable-thead tr th:focus {
	box-shadow: none;
}

.table-recebimentos .p-sortable-column-icon {
	color: var(--cinza) !important;
}

.table-recebimentos .p-paginator {
	background-color: transparent !important;
	border: none !important;
}

.table-recebimentos .p-datatable-header {
	display: none;
}
