* {
	box-sizing: border-box;
}

html {
	height: 100%;
}

body {
	font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
	font-size: $fontSize;
	color: $textColor;
	background-color: $bodyBgColor;
	margin: 0;
	padding: 0;
	min-height: 100%;
}

a {
	text-decoration: none;
}

.layout-wrapper {
	padding: 0;

	.layout-topbar {
		position: fixed;
		height: 50px;
		padding: 0.7em 1em 0em 1.5em;
		color: var(--branco);
		z-index: 999;
		right: 0;
		background: #006095;
		@include transition(left $transitionDuration);

		.layout-menu-button {
			cursor: pointer;
			float: left;
			text-decoration: none;
			color: $topbarItemColor;

			span {
				font-size: 2em;
			}
		}

		.layout-menu-container {
			display: flex;
			justify-content: flex-end;

			.layout-menu-container-icons {
				display: inline-flex;

				span {
					margin-left: 15px;
					color: $topbarItemColor;
					cursor: pointer;
					padding-top: 5px;
					font-size: 1.7em;
				}
			}
		}
	}

	.p-scrollpanel {
		background: transparent;
		border-radius: 0;
		border: none;

		.p-scrollpanel-content {
			overflow: scroll;
		}

		.p-scrollpanel-bar {
			background: $scrollPanelBgColor;
			@include opacity(0.3);
		}

		.p-scrollpanel-hidden {
			display: block;
			visibility: hidden;
		}

		.layout-sidebar-scroll-content {
			width: 100%;
			padding-bottom: 120px;
		}
	}

	.layout-sidebar {
		position: fixed;
		width: 250px;
		height: 100%;
		z-index: 999;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		@include transition(left $transitionDuration);
		@include linear-gradient($menuBgColorFirst, $menuBgColorLast);
		@include shadow(0 0 6px 0 rgba(0, 0, 0, 0.16));

		.layout-logo {
			text-align: center;
			margin-top: 24px;
		}

		.profile {
			text-align: center;
			padding: 20px 0;

			img {
				width: 56px;
				margin: 10px;
			}

			.profile-link {
				cursor: pointer;
				color: $menuitemColor;
				display: inline-block;
				margin-bottom: 10px;
				@include transition(color $transitionDuration);

				i {
					display: inline-block;
					font-size: 16px;
				}

				&:hover {
					color: $menuitemHoverColor;
				}
			}

			> ul {
				overflow: hidden;
				background-color: $menuitemActiveBgColor;
				text-align: left;
				max-height: 0;
				@include transition-duration($transitionDuration);
				@include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

				&.profile-expanded {
					max-height: 500px;
				}

				li {
					a {
						border: 0 none;
					}

					&:last-child {
						> a {
							border: 0 none;
						}
					}
				}
			}
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				a {
					cursor: pointer;
					position: relative;
					color: $menuitemColor;
					text-decoration: none;
					font-size: $fontSize;
					padding: 1em 15px 1em 1em;
					display: block;
					border-top: 1px solid $menuitemBorderColor;

					i {
						font-size: 18px;
						vertical-align: middle;
					}

					span {
						margin-left: 0.25em;
						vertical-align: middle;
					}

					.menuitem-toggle-icon {
						float: right;
					}

					&:hover {
						color: $menuitemHoverColor;
					}
				}

				&.active-menuitem {
					> a {
						background-color: $menuitemActiveBgColor;
						color: $menuitemActiveColor;
					}

					> ul {
						max-height: 500px;
					}
				}

				ul {
					background-color: $menuitemActiveBgColor;
					overflow: hidden;
					padding-left: 1.5em;
					max-height: 0;
					@include transition-property(max-height);
					@include transition-duration(0.4s);
					@include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

					li {
						a {
							cursor: pointer;
							padding: 0.75em 33px 0.75em 0.75em;
							font-size: $submenuFontSize;
							border-top: 0 none;
						}

						&:last-child {
							> a {
								border-bottom: 0 none;
							}
						}
					}
				}
			}

			.menuitem-toggle-icon {
				float: right;
				margin-top: 2px;
			}

			.menuitem-badge {
				margin-top: 3px;
				font-size: 10px;
				float: right;
				width: 16px;
				height: 16px;
				line-height: 16px;
				text-align: center;
				color: $menuitemBadgeColor;
				background-color: $menuitemBadgeBgColor;
				@include border-radius(50%);
			}
		}

		&.layout-sidebar-dark {
			@include linear-gradient($menuDarkBgColorFirst, $menuDarkBgColorLast);

			.profile {
				> a {
					color: $menuitemDarkColor;

					&:hover {
						color: $menuitemDarkHoverColor;
					}
				}

				> ul {
					background-color: $menuitemDarkActiveBgColor;

					li {
						a {
							border: 0 none;
						}
					}
				}
			}

			ul {
				li {
					a {
						color: $menuitemDarkColor;
						border-top: 1px solid $menuitemDarkBorderColor;

						&:hover {
							color: $menuitemDarkHoverColor;
						}
					}

					&.active-menuitem {
						> a {
							background-color: $menuitemDarkActiveBgColor;
							color: $menuitemDarkActiveColor;
						}
					}

					ul {
						background-color: $menuitemDarkActiveBgColor;

						li {
							a {
								border: none;
							}
						}
					}
				}
			}
		}
	}

	.layout-main {
		@include transition(margin-left $transitionDuration);
		padding: 66px 16px 16px 16px;
	}

	.layout-footer {
		@include transition(margin-left $transitionDuration);
		background-color: $footerBgColor;
		padding: 1em 2em;

		img {
			vertical-align: middle;
		}

		.footer-text {
			vertical-align: middle;
		}
	}
}

/* Responsive */
@media (min-width: 1025px) {
	.layout-wrapper {
		&.layout-overlay {
			.layout-sidebar {
				left: -250px;
			}

			.layout-topbar {
				left: 0;
			}

			.layout-main,
			.layout-footer {
				margin-left: 0;
			}

			&.layout-overlay-sidebar-active {
				.layout-sidebar {
					left: 0;
				}

				.layout-topbar {
					left: 250px;
				}
			}
		}

		&.layout-static {
			.layout-sidebar {
				left: 0;
			}

			.layout-topbar {
				left: 250px;
			}

			.layout-main,
			.layout-footer {
				margin-left: 250px;
			}

			&.layout-static-sidebar-inactive {
				.layout-sidebar {
					left: -250px;
				}

				.layout-topbar {
					left: 0;
				}

				.layout-main,
				.layout-footer {
					margin-left: 0;
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.layout-wrapper {
		.layout-topbar {
			left: 0;
		}

		.layout-main,
		.layout-footer {
			margin-left: 0;
		}

		.layout-sidebar {
			left: -250px;
		}

		.layout-mask {
			display: none;
			position: fixed;
			width: 100%;
			height: 100%;
			top: 50px;
			left: 0;
			z-index: 998;
			background-color: $maskBgColor;
			@include opacity(0.7);
		}

		&.layout-mobile-sidebar-active {
			.layout-sidebar {
				left: -0;
			}
			.layout-topbar {
				left: 235px;
			}

			.layout-mask {
				display: block;
			}
		}
	}

	.body-overflow-hidden {
		overflow: hidden;
	}
}
