#loading-content {
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    z-index: 2000;
}

#spinner {
    display: flex;
}
