.p-component {
	font-family: 'Open Sans', sans-serif;
}

/*Css responsável por deixar o componente de loading azul*/

@keyframes p-progress-spinner-color {
	100%,
	0% {
		stroke: var(--principal);
	}
}

/* remover a borda azulada ao dar foco nos textareas */
textarea:focus {
	box-shadow: 0 0 0 0;
	border: 0 none;
	outline: 0;
}

/* CSS responsável por remover a estilização do h1 do paper*/

.card h1 {
	border: none !important;
	margin: 0.5em 0 0.5em 0;
	padding: 0.1em;
	font-size: 24px;
}

/*Css responsável por deixar os campos com erros vermelhin*/

.p-input-error {
	border-color: var(--vermelho) !important;
}

/*Css responsável por deixar os campos com warning lanajados*/

.p-input-warning {
	border-color: var(--warning) !important;
}

/*Css responsável por deixar o alertify bonito*/

.ajs-message {
	border-radius: 4px !important;
	text-align: left !important;
	border: none !important;
	text-shadow: none !important;
	font-weight: 500 !important;
	font-family: Roboto, Helvetica, Arial, sans-serif !important;
	letter-spacing: 0.01071em !important;
	box-shadow:
		0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 6px 10px 0px rgba(0, 0, 0, 0.14),
		0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.ajs-success {
	background: #4caf50 !important;
}

.ajs-error {
	background: #f44336 !important;
}

.ajs-warning {
	background: #e9db5d !important;
}

/*Css para os marcadores do calendar*/

.p-datepicker-month {
	border-radius: 3px;
	height: 25px;
}

.p-datepicker-year {
	border-radius: 3px;
	height: 25px;
}

/*CSS do react select*/

.reactSelect__control {
	border: 1px solid #a6a6a6;
	min-height: 20px !important;
	font-family: 'Open Sans', 'Helvetica Neue', sans-serif !important;
	font-size: 14px !important;
	border-radius: 3px !important;
}

.reactSelect__control:hover {
	border-collapse: 1px solid #a6a6a6;
}

.reactSelect__single-value {
	top: 40% !important;
}

.reactSelect__placeholder {
	top: 40% !important;
}

.creatableSelect__control {
	border: 1px solid #a6a6a6;
	min-height: 20px !important;
	font-family: 'Open Sans', 'Helvetica Neue', sans-serif !important;
	font-size: 14px !important;
	border-radius: 3px !important;
	border-collapse: 1px solid #a6a6a6;
	box-shadow: none !important;
}

.css-1g6gooi {
	padding-top: 0px !important;
}

.react-select-base {
	width: 100%;
}

.internal-single-select {
	width: 100%;
}

.ajs-confirm-erro-tratado-title {
	font-size: 17px;
}

.ajs-content {
	top: 64px !important;
}

.ajs-confirm-erro-tratado-content {
	font-size: 15px;
}

.ajs-header {
	background: var(--branco) !important;
	border-bottom: none !important;
}

.ajs-footer {
	background: var(--branco) !important;
	border-top: none !important;
}

body .p-inputtext:focus {
	box-shadow: 1.5px 1.51px #c5c5c5 !important;
}

body {
	font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.ql-align-center {
	text-align: center;
}

.ql-align-justify {
	text-align: justify;
}

.ql-align-right {
	text-align: right;
}

.tab-accordion-lite .p-accordion-header a {
	background-color: var(--branco) 0 !important;
	border: none !important;
	margin-top: 8px;
}

.tab-accordion-lite .p-accordion-content {
	border: none !important;
}

.tab-accordion-lite .p-accordion-header a span {
	color: black !important;
	background-color: var(--branco) 0 !important;
}

/*Tabela padrão*/

.table th {
	background-color: transparent !important;
	color: var(--cinzaDark) !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.table .p-datatable-scrollable-header {
	background-color: transparent !important;
}

.table .p-datatable-header {
	background-color: transparent !important;
	border: none !important;
	align-items: center;
	padding: 0.25rem 1rem;
}

.table-assinaturas .p-datatable-header {
	padding: 0rem !important;
}

.table .p-datatable-tbody tr td {
	border-left: none !important;
	border-right: none !important;
	border-top: 1px solid #c8c8c8;
	border-bottom: 1px solid #c8c8c8;
	text-align: start;
	padding: 0.429em 0.857em;
	align-items: center !important;
}

.table .p-datatable-tbody .p-datatable-row td {
	border-left: none;
	border-right: none;
}

.table .p-datatable-thead tr th {
	border: none !important;
	text-align: start !important;
	padding: 0.571em 0.857em;
	font-weight: 700;
	font-size: 14px;
}

.table .p-datatable-thead tr th:focus {
	box-shadow: none;
}

.table .p-sortable-column-icon {
	color: var(--cinzaDark) !important;
}

.table .p-paginator {
	background-color: transparent !important;
	border: none !important;
}

.table .p-datatable-emptymessage td {
	border: none !important;
}

/*Fim tabela padrão*/

/*Treetable*/

body .tree-table-categorias .p-treetable-thead tr th {
	border: none;
	scrollbar-width: none;
	color: var(--cinza);
	background-color: var(--branco);
	border-left: none;
	border-right: none;
}

body .tree-table-categorias .p-treetable-tbody > tr > td {
	background: inherit;
	border-left: none;
	border-right: none;
	padding: 0.429em 0.857em;
}

body .tree-table-categorias span.p-treetable-toggler.p-unselectable-text {
	display: none;
}

body .p-treetable .p-treetable-header {
	background-color: var(--branco) 0;
	color: var(--cinzaDark);
	border: none;
	padding: 0px;
	text-align: center;
}

/*Fim treetable*/

.paginator {
	background-color: var(--branco) 0 !important;
	border: none !important;
	width: 100%;
}

.select-button .p-button-text-only {
	background-color: var(--branco) 0 !important;
	border-radius: 20px !important;
	margin: 5px;
}

.select-button .p-highlight {
	background-color: var(--principal) !important;
	border-radius: 20px !important;
}

.select-button .p-button-text {
	padding: 2px 1em !important;
}

/* Tabview light com scrollable lateral */

.tab-view {
	width: 100%;
	max-width: calc(100%) !important;
}

.tab-view ul {
	overflow: scroll;
	width: 100%;
	display: inline-flex;
	overflow-x: auto;
	overflow-y: hidden;
}

.tab-view ul::-webkit-scrollbar {
	background: #f4f4f4;
	height: 10px;
	cursor: pointer;
}

.tab-view ul::-webkit-scrollbar-track {
	background: #f4f4f4;
}

.tab-view ul::-webkit-scrollbar-thumb {
	background: #dad7d7;
	cursor: pointer;
}

.tab-view .p-tabview-nav li {
	background-color: transparent !important;
}

.tab-view .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--principal) !important;
	border-bottom: 5px solid var(--principal) !important;
}

.tab-view .p-tabview-nav li a {
	border: none !important;
	background-color: transparent !important;
}

.tab-view .p-tabview-panels {
	background-color: transparent !important;
	border: 1px solid #c8c8c8;
	padding: 0.571em 1em 0 1em;
}

/* tab view com a ultima aba escondida - serve para mostrar um valor vazio*/
.tab-view-last-hidden {
	width: 100%;
	max-width: calc(100%) !important;
}

.tab-view-last-hidden ul {
	overflow: scroll;
	width: 100%;
	display: inline-flex;
	overflow-x: auto;
	overflow-y: hidden;
}

.tab-view-last-hidden ul::-webkit-scrollbar {
	background: #f4f4f4;
	height: 10px;
	cursor: pointer;
}

.tab-view-last-hidden ul::-webkit-scrollbar-track {
	background: #f4f4f4;
}

.tab-view-last-hidden ul::-webkit-scrollbar-thumb {
	background: #dad7d7;
	cursor: pointer;
}

.tab-view-last-hidden .p-tabview-nav li {
	background-color: transparent !important;
}

.tab-view-last-hidden .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--principal) !important;
	border-bottom: 4px solid var(--principal) !important;
}

.tab-view-last-hidden .p-tabview-nav li a {
	border: none !important;
	background-color: transparent !important;
}

.tab-view-last-hidden .p-tabview-panels {
	background-color: transparent !important;
	border-top: 1px solid #c8c8c8 !important;
	padding: 0.571em 1em 0 1em;
}

.tab-view-last-hidden .p-tabview-nav li.p-highlight a {
	border: none !important;
}

/* tab view com borda superior*/
.tab-view-top-border {
	width: 100%;
	max-width: calc(100%) !important;
}

.tab-view-top-border ul {
	overflow: scroll;
	width: 100%;
	display: inline-flex;
	overflow-x: auto;
	overflow-y: hidden;
}

.tab-view-top-border ul::-webkit-scrollbar {
	background: #f4f4f4;
	height: 10px;
	cursor: pointer;
}

.tab-view-top-border ul::-webkit-scrollbar-track {
	background: #f4f4f4;
}

.tab-view-top-border ul::-webkit-scrollbar-thumb {
	background: #dad7d7;
	cursor: pointer;
}

.tab-view-top-border .p-tabview-nav li {
	background-color: transparent !important;
}

.tab-view-top-border .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--principal) !important;
	border-bottom: 4px solid var(--principal) !important;
}

.tab-view-top-border .p-tabview-nav li a {
	border: none !important;
	background-color: transparent !important;
}

.tab-view-top-border .p-tabview-panels {
	background-color: transparent !important;
	border-top: 1px solid #c8c8c8 !important;
	padding: 0.571em 1em 0 1em;
}

/* Tabview light SEM BORDAS com scrollable lateral */

.tab-view-no-border {
	width: 100%;
	max-width: calc(100%) !important;
}

.tab-view-no-border ul {
	overflow: scroll;
	width: 100%;
	display: inline-flex;
	overflow-x: auto;
	overflow-y: hidden;
}

.tab-view-no-border ul::-webkit-scrollbar {
	background: #f4f4f4;
	height: 10px;
	cursor: pointer;
}

.tab-view-no-border ul::-webkit-scrollbar-track {
	background: #f4f4f4;
}

.tab-view-no-border ul::-webkit-scrollbar-thumb {
	background: #dad7d7;
	cursor: pointer;
}

.tab-view-no-border .p-tabview-nav li {
	background-color: transparent !important;
}

.tab-view-no-border .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--principal) !important;
	border-bottom: 4px solid var(--principal) !important;
}

.tab-view-no-border .p-tabview-nav li a {
	border: none !important;
	background-color: transparent !important;
}

.tab-view-no-border .p-tabview-panels {
	background-color: transparent !important;
	padding: unset !important;
	border: unset !important;
}

/* Estilo do Tree cmponent (tela de papeis e permissões) */
.p-tree .p-treenode {
	overflow: scroll;
	padding: unset !important;
	scrollbar-width: none;
}

.p-tree .p-treenode::-webkit-scrollbar {
	display: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
	background-color: transparent !important;
	color: var(--cinzaDark) !important;
	border: 0px !important;
}

.p-tree-select .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
	background-color: transparent !important;
	color: var(--cinzaDark) !important;
	border: 0px !important;
}

.p-tree-select .p-tree .p-tree-container .p-treenode .p-treenode-content:hover {
	background-color: var(--principalHoverTable) !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler {
	color: #848484 !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
	box-shadow: unset !important;
}

/* Estilo ao passar o mouse por cima dos cards totalizadores das listagens */

.p-total-list-cards:hover {
	box-shadow: 2px 2px 10px 0px #999999;
}

/*Estilo do popup aberto no pwa*/

.popup-instalar-app {
	z-index: 999999 !important;
	opacity: 1 !important;
	bottom: 0 !important;
	left: 5px !important;
}

.popup-instalar-app.mobile {
	width: calc(100% - 10px) !important;
}

.popup-instalar-app .p-growl-item {
	padding: 12px !important;
}

.popup-instalar-app .p-growl-message {
	width: 100% !important;
	margin-left: 0 !important;
}

.popup-instalar-app .p-growl-item-container {
	background-color: var(--branco) !important;
	border-radius: 5px !important;
	-webkit-box-shadow: 3px 3px 15px 0px rgba(125, 125, 125, 1) !important;
	-moz-box-shadow: 3px 3px 15px 0px rgba(125, 125, 125, 1) !important;
	box-shadow: 3px 3px 15px 0px rgba(125, 125, 125, 1) !important;
	margin-bottom: 5px !important;
}

.popup-instalar-app .pi {
	font-size: 1em !important;
}

.popup-instalar-app .button {
	text-align: right;
}

.popup-instalar-app .pi-info-circle {
	display: none;
}

.popup-instalar-app .p-growl-details {
	margin-top: 0 !important;
}

/* Ajusta o tamanho do ícone do primereact*/

.p-checkbox-icon {
	font-size: 1em !important;
	margin-top: 2px !important;
}

.p-sortable-column-icon {
	font-size: 1em !important;
}

.p-overlaypanel-close-icon {
	font-size: 1em !important;
	margin-top: 1px !important;
}

.p-tree-toggler-icon {
	font-size: 1em !important;
}

/*Ajuste scroll panel*/

.p-scrollpanel-content {
	padding-right: 0px !important;
}

#comTomTicketChatWidget {
	min-width: 0px !important;
	width: 51px !important;
}

.tomticketChat-status {
	display: none !important;
}

.p-overlaypanel:before {
	content: none;
}

.p-overlaypanel::after {
	content: none;
}

/* Definição de estilo para os cards atuais das listagens e formulários */

.screen-max-width {
	max-width: 1366px !important;
	margin: auto !important;
}

.card-default {
	box-shadow:
		rgba(0, 0, 0, 0.16) 0px 3px 6px,
		rgba(0, 0, 0, 0.23) 0px 3px 6px;
	border-radius: 3px;
}

.link_to:hover {
	text-decoration: underline;
	cursor: pointer;
}

.fieldset {
	border: none !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.fieldset .p-fieldset-legend {
	font-weight: 700;
	font-size: 16px;
	border: none !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding: 0;
	background: none;
}

/* Ajustes após mudança de theme */

.p-button {
	transition: 0.5s ease;
	padding: 0.3rem 0.5rem;
}

.p-button.p-button-icon-only {
	padding: 0;
}

body .p-button.p-button-secondary:enabled:hover,
body .p-buttonset.p-button-secondary > .p-button:enabled:hover {
	background-color: #c8c8c8;
}

body .p-button.p-button-secondary:enabled {
	background-color: var(--cinzaBackground);
}

.p-breadcrumb {
	padding: 0.5rem 1rem;
}

.p-inputtext {
	padding: 0.4rem 0.5rem;
	font-size: 14px;
}

.p-checkbox-label {
	margin: 0 0 0 0.5em;
	vertical-align: middle;
	font-weight: 400;
	/* font-size: 14px; */
}

.p-checkbox .p-checkbox-box {
	border: 1px solid #ced4da;
}

.p-paginator {
	padding: 0;
	font-size: 14px;
	text-align: center;
}

.p-paginator-icon {
	font-size: 1em !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	color: var(--branco);
	height: 2rem;
	width: 2rem;
	min-width: 2rem;
}

.p-paginator .p-paginator-first {
	height: 2rem;
	width: 2rem;
	min-width: 2rem;
}

.p-paginator .p-paginator-prev {
	height: 2rem;
	width: 2rem;
	min-width: 2rem;
}

.p-paginator .p-paginator-next {
	height: 2rem;
	width: 2rem;
	min-width: 2rem;
}

.p-paginator .p-paginator-last {
	height: 2rem;
	width: 2rem;
	min-width: 2rem;
}

.p-paginator .p-dropdown {
	border: none !important;
}

.div-paginator-right-content {
	display: flex;
	justify-content: flex-end;
}

.p-dropdown-label.p-inputtext {
	padding: 0.5rem 0.5rem;
}

.p-dropdown-panel .p-dropdown-items {
	font-weight: 400;
	font-size: 14px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
	color: var(--branco);
}

.p-sidebar .p-sidebar-header {
	z-index: 99999;
	padding: 0;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
	border-radius: 3px;
}

.p-sidebar .p-sidebar-content {
	position: absolute;
	padding: 0;
	top: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	color: var(--cinzaDark);
	padding: 1rem 1rem 0.571rem 1rem;
	font-weight: 700;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
	box-shadow: none;
}

.p-tabview .p-tabview-nav {
	border: none;
	background-color: transparent !important;
}

.tab-view-dashboard .p-tabview-nav {
	display: flex;
	justify-content: center;
}

.tab-view-dashboard-mobile .p-tabview-nav {
	display: flex;
	justify-content: flex-start !important;
}

.tab-view-dashboard .p-tabview-nav li {
	margin-bottom: 0.5rem;
}

.p-tabview-title {
	font-size: 14px;
}

.p-unselectable-text .p-tabview-selected .p-highlight {
	border-bottom: 2px solid var(--principal) !important;
}

.p-unselectable-text .p-tabview-selected .p-highlight {
	border-bottom: 2px solid var(--principal) !important;
}

.p-fieldset .p-fieldset-content {
	padding: 0.5em 0em;
}

.p-button.p-button-primary:hover {
	background: var(--principalDark) !important;
	border: 1px solid var(--principalDark) !important;
}

.p-button.p-button-secondary.p-button-icon-only:enabled:hover {
	background-color: #c8c8c8 !important;
	border: 1px solid #c8c8c8 !important;
}

.p-button.p-button-warning:hover {
	background: var(--warning) !important;
	border: 1px solid var(--warning) !important;
}

.p-button.p-button-success {
	background: var(--verdeButton) !important;
	border: 1px solid var(--verdeButton) !important;
}

.p-button.p-button-success:hover {
	background: var(--verde) !important;
	border: 1px solid var(--verde) !important;
}

.p-button.button-add-icon-only:hover {
	background: var(--brancoTransparente) !important;
	border: 1px solid var(--brancoTransparente) !important;
}

.p-button.p-button-danger:hover {
	background-color: var(--vermelho) !important;
	border: 1px solid var(--vermelho) !important;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
	padding: 0.35em;
}

.p-datatable-table {
	font-size: 14px;
	padding: 0.429em 0.857em;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
	background-color: transparent;
	box-shadow: none !important;
}

.p-link:focus {
	box-shadow: none;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
	box-shadow: none;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
	font-weight: 500;
}

body .p-datatable .p-datatable-tbody > tr:not(.p-datatable-emptymessage):hover {
	font-weight: #e9ecef;
	background-color: var(--principalHoverTable);
}

body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover td {
	background-color: var(--principalHoverTable);
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
	background: none;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
	background-color: #eef3f6 !important;
}

body .p-datatable .p-datatable-tbody .p-datatable-emptymessage > td {
	background-color: transparent !important;
}

.p-card .p-card-content {
	padding: 1rem 0 0;
}

.step-nfce-pessoa .p-card .p-card-content {
	padding: 0rem 0 0 0;
}

.nfce-total .p-card .p-card-content {
	padding: 0rem 0 0 0;
}

.p-card .p-card-body {
	padding: 0 1rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
	border: none;
	outline: none;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
	outline: none;
	box-shadow: none;
}

.p-treeselect .p-treeselect-trigger {
	border-left: 1px solid #ced4da;
	outline: none;
}

.p-treeselect .p-treeselect-trigger:focus {
	outline: none;
	box-shadow: none;
}

.tab-view-categorias .p-tabview-nav {
	justify-content: center;
}

.tab-view-categorias .p-tabview-nav li.p-highlight a {
	margin-bottom: 5px;
	background-color: initial;
	border-bottom: 2px solid var(--principal);
	color: var(--principal);
}

.p-checkbox-label.p-checkbox-label-categorias {
	font-weight: 400;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button,
.p-fileupload-choose.p-button-secondary {
	color: var(--cinza);
}

.p-fieldset-legend > a,
.p-fieldset-legend > span {
	font-size: 16px;
	color: var(--cinza);
	padding: 0.5rem;
}

.css-lw34l0-control {
	border: 1px solid #ced4da !important;
}

.css-1xe7wop-control {
	border: 1px solid #ced4da !important;
}

body .p-button.p-button-secondary,
body .p-buttonset.p-button-secondary > .p-button {
	border: none;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
	padding: 0.571em 1em;
}

.p-datatable-scrollable .p-datatable-thead > tr,
.p-datatable-scrollable .p-datatable-tbody > tr,
.p-datatable-scrollable .p-datatable-tfoot > tr {
	flex-wrap: wrap;
}

.grid-select-filtro > div {
	max-width: calc(100%) !important;
	padding: 1rem 0;
}

.grid-select-filtro {
	display: flex !important;
	justify-content: center !important;
	flex-wrap: wrap !important;
	gap: 0.5rem;
}

.grid-select-filtro .p-button {
	border-radius: 20px !important;
	padding: 0.2rem 1rem;
	overflow: initial;
}

@media (max-width: 475px) {
	.grid-select-filtro .p-button {
		width: 100%;
		overflow: initial;
	}

	.grid-select-filtro {
		padding-bottom: 0.5rem;
		justify-content: start !important;
		flex-wrap: nowrap !important;
	}
	.grid-select-filtro .p-button:first-child {
		margin-left: 4rem;
	}

	.grid-select-filtro-mobile .p-button:first-child {
		margin-left: 0 !important;
	}
}

.p-button:focus {
	box-shadow: none;
}

.grid-select-filtro::-webkit-scrollbar {
	height: 6px;
}

.grid-select-filtro::-webkit-scrollbar-track {
	background: transparent;
}

.grid-select-filtro::-webkit-scrollbar-thumb {
	background: #dad7d7;
}

.grid-select-filtro .select-button::-webkit-scrollbar {
	background: #f4f4f4;
	height: 6px;
	cursor: pointer;
}

.grid-select-filtro .select-button::-webkit-scrollbar-track {
	background: #f4f4f4;
}

.grid-select-filtro .select-button::-webkit-scrollbar-thumb {
	background: #dad7d7;
	cursor: pointer;
}

.card-importacoes {
	padding: 0 0 1rem 0;
}

.p-radiobutton-label {
	cursor: pointer;
	margin-left: 0.5em;
}

.p-toast .p-toast-message.p-toast-message-info {
	background: var(--branco);
	color: #000;
}

.p-checkbox-disabled {
	cursor: not-allowed !important;
}

/* Customização do scrollbar */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
	background-color: rgba(177, 177, 177, 0.2);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(177, 177, 177, 0.5);
	border-radius: 10px;
	opacity: 1;
	transition: background-color 0.5s;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(177, 177, 177, 0.8);
}

/* Remove arrows do input field com type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

/* Corrige o alinhamento dos botoes dentro do form actions */
.form-actions button {
	margin: 5px 5px 5px 0 !important;
}

@media only screen and (max-width: 576px) {
	.form-actions {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.form-actions button {
		width: 100%;
	}

	.form-actions span {
		margin: 0px 5px;
	}
}

:not(:root):fullscreen::backdrop {
	background-color: var(--cinzaBackground);
}

.p-overlaypanel {
	z-index: 1000 !important;
}

.p-component {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 1rem;
	font-weight: normal;
  }