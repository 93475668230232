#inputColor {
	-webkit-appearance: none;
	padding: 0;
	border: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	cursor: pointer;
}
#inputColor::-webkit-color-swatch {
	border: none;
	border-radius: 50%;
	padding: 0;
}
#inputColor::-webkit-color-swatch-wrapper {
	border: none;
	border-radius: 50%;
	padding: 0;
}
