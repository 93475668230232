/*CSS responsável pelas cores da microsys*/

body .p-editor-container .p-editor-toolbar {
	background-color: var(--cinzaBackground);
}
body .p-fileupload .p-fileupload-buttonbar {
	background-color: var(--cinzaBackground);
}
body .p-button.p-button-secondary,
body .p-buttonset.p-button-secondary > .p-button {
	background-color: var(--cinzaBackground);
}
body .p-panel .p-panel-titlebar {
	background-color: var(--cinzaBackground);
}
body .p-fieldset .p-fieldset-legend a {
	background-color: var(--cinzaBackground);
}
body .p-accordion .p-accordion-header a {
	background-color: var(--cinzaBackground);
}
body .p-tabview.p-tabview-top .p-tabview-nav li,
body .p-tabview.p-tabview-bottom .p-tabview-nav li,
body .p-tabview.p-tabview-left .p-tabview-nav li,
body .p-tabview.p-tabview-right .p-tabview-nav li {
	background-color: var(--cinzaBackground);
}
body .p-toolbar {
	background-color: var(--cinzaBackground);
}
body .p-paginator {
	background-color: var(--cinzaBackground);
}
body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
	background-color: var(--branco);
	border: none;
}
body .p-datatable .p-datatable-thead > tr > th {
	background-color: var(--cinzaBackground);
}
body .p-datatable .p-datatable-tfoot > tr > td {
	background-color: var(--cinzaBackground);
}
body .p-datatable .p-datatable-scrollable-header,
body .p-datatable .p-datatable-scrollable-footer {
	background-color: var(--cinzaBackground);
}
body .p-datagrid .p-datagrid-header {
	background-color: var(--cinzaBackground);
}
body .p-datalist .p-datalist-header {
	background-color: var(--cinzaBackground);
}
body .p-datascroller .p-datascroller-header {
	background-color: var(--cinzaBackground);
}
body .p-dataview .p-dataview-header {
	background-color: var(--cinzaBackground);
}
body .fc th {
	background-color: var(--cinzaBackground);
}
body .fc .fc-divider {
	background-color: var(--cinzaBackground);
}
body .p-picklist .p-picklist-caption {
	background-color: var(--cinzaBackground);
}
body .p-orderlist .p-orderlist-caption {
	background-color: var(--cinzaBackground);
}
body .p-carousel .p-carousel-header {
	background-color: var(--cinzaBackground);
}
body .p-carousel .p-carousel-footer {
	background-color: var(--cinzaBackground);
}
body .p-treetable .p-treetable-header,
body .p-treetable .p-treetable-footer {
	background-color: var(--cinzaBackground);
}
body .p-treetable thead > tr > th {
	background-color: var(--cinzaBackground);
}
body .p-treetable tfoot > tr > td {
	background-color: var(--cinzaBackground);
}
body .p-dialog .p-dialog-titlebar {
	background-color: var(--cinzaBackground);
}
body .p-lightbox .p-lightbox-caption {
	background-color: var(--cinzaBackground);
}
body .p-menu .p-submenu-header {
	background-color: var(--cinzaBackground);
}

body .p-menu .p-menuitem-link:not(.p-disabled):hover {
	background-color: var(--principalHoverTable);
}

body .p-slidemenu .p-slidemenu-backward {
	background-color: var(--cinzaBackground);
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
	background-color: var(--cinzaBackground);
}
body .p-panelmenu .p-panelmenu-header > a {
	background-color: var(--cinzaBackground);
}
body .autocompletePesquisa {
	background-color: var(--cinzaBackground);
}

body a {
	color: var(--principal);
}
body .p-inputtext:enabled:focus:not(.p-error) {
	border-color: var(--principal);
}
body .p-inputtext:enabled:hover:not(.p-error) {
	border-color: var(--principal);
}
body .p-checkbox .p-checkbox-box.p-focus {
	border-color: var(--principal);
}
body .p-checkbox .p-checkbox-box.p-highlight {
	border-color: var(--principal);
	background-color: var(--principal);
}
body .p-radiobutton .p-radiobutton-box.p-focus {
	border-color: var(--principal);
	color: var(--principal);
}
body .p-radiobutton .p-radiobutton-box.p-highlight {
	border-color: var(--principal);
	background-color: var(--principal);
}
body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
	background-color: var(--principal);
}
body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
	border-color: var(--principal);
}
body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
	background: var(--principal);
}
body .p-chips > ul.p-inputtext:not(.p-disabled).p-focus {
	border-color: var(--principal);
}
body .p-chips > ul.p-inputtext .p-chips-token {
	background: var(--principal);
}
body .p-dropdown:not(.p-disabled):focus {
	border-color: var(--principal);
}
body .p-dropdown:not(.p-disabled).p-focus {
	border-color: var(--principal);
}
body .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
	color: var(--principal);
}
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
	background-color: var(--principal);
}
body .p-multiselect:not(.p-disabled):focus {
	border-color: var(--principal);
}
body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
	color: var(--principal);
}
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
	background-color: var(--principal);
}
body .p-listbox .p-listbox-header .p-listbox-filter-container .p-listbox-filter-icon {
	color: var(--principal);
}
body .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
	background-color: var(--principal);
}
body .p-rating:not(.p-disabled):not(.p-rating-readonly) a:hover {
	color: var(--principal);
}
body .p-slider .p-slider-range {
	background-color: var(--principal);
}
body .p-slider:not(.p-disabled) .p-slider-handle:hover {
	border: 2px solid var(--principal);
}
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:hover,
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:hover {
	color: var(--principal);
}
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:focus,
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:focus {
	color: var(--principal);
}
body .p-datepicker table td > a.p-highlight {
	background-color: var(--principal);
}
body .p-datepicker .p-timepicker a:hover {
	color: var(--principal);
}
body .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
	background-color: var(--principal);
}
body .p-button {
	background-color: var(--principal);
	border: 1px solid var(--principal);
}
body .p-togglebutton.p-highlight {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-selectbutton .p-button.p-highlight {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-button.p-button-info,
body .p-buttonset.p-button-info > .p-button {
	background-color: var(--principal);
	border: 1px solid var(--principal);
}
body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
	background-color: var(--principal);
	border: 1px solid var(--principal);
}
/*
body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight {background-color: var(--principal);border: 1px solid var(--principal); }
*/
body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	background-color: var(--principal);
}
body .p-datatable .p-sortable-column.p-highlight {
	background-color: var(--principal);
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
	background-color: var(--azulHoverTransparente);
}
body .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
	box-shadow: inset 0 -2px 0 0 var(--principal);
}
body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
	background-color: var(--azulHoverTransparente);
}

body .p-datatable .p-column-resizer-helper {
	background-color: var(--principal);
}
body .fc .fc-toolbar .p-button {
	background-color: var(--principal);
	border: 1px solid var(--principal);
}
body .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
	color: var(--principal);
}
body .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
	background-color: var(--principal);
}
body .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
	color: var(--principal);
}
body .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
	background-color: var(--principal);
}
body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
	background-color: var(--principal);
}
body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight-contextmenu {
	border: 1px dashed var(--principal);
}
body .p-tree .p-tree-container .p-treenode-droppoint.p-treenode-droppoint-active {
	background-color: var(--principal);
}
body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
	background-color: var(--principal);
}
body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox .p-icon {
	color: var(--principal);
}
body .p-organizationchart .p-organizationchart-node-content.p-highlight {
	background-color: var(--principal);
}
body .p-treetable .p-sortable-column.p-highlight {
	background-color: var(--principal);
}
body .p-treetable .p-treetable-row-selectable.p-highlight {
	background-color: var(--principal);
}
body .p-overlaypanel .p-overlaypanel-close {
	background-color: var(--principal);
}
body .p-steps .p-steps-item.p-highlight .p-steps-number {
	background: var(--principal);
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight {
	background-color: var(--principal);
	border: 1px solid var(--principal);
}
body .p-panelmenu .p-panelmenu-header.p-highlight > a {
	border: 1px solid var(--principal);
	background-color: var(--principal);
}
body .p-progressbar .p-progressbar-value {
	background: var(--principal);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a {
	background-color: var(--principal);
	border: 0px solid var(--principal);
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a {
	background-color: var(--principal);
	border: 0px solid var(--principal);
}
body .p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-left .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-right .p-tabview-nav li a:not(.p-disabled):focus {
	box-shadow: none;
}

body a:active {
	color: var(--principal);
}
body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
	border-color: var(--principal);
	background-color: var(--principal);
}
body .p-checkbox .p-checkbox-box.p-highlight.p-focus {
	border-color: var(--principal);
	background-color: var(--principal);
}
body .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
	border-color: var(--principal);
	background-color: var(--principal);
}
body .p-radiobutton .p-radiobutton-box.p-highlight.p-focus {
	border-color: var(--principal);
	background-color: var(--principal);
}
body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
	background: var(--principal);
}
body .p-fileupload-choose:not(.p-disabled):active {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-button:enabled:active {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-togglebutton.p-highlight.p-focus {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-selectbutton .p-button.p-highlight.p-focus {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-button.p-button-info:enabled:active,
body .p-buttonset.p-button-info > .p-button:enabled:active {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a {
	border: 1px solid var(--principal);
	background-color: var(--principal);
}
/*
body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover {border: 1px solid var(--principal);background-color: var(--principal); }
*/
.p-overlaypanel .p-overlaypanel-close:hover {
	background-color: var(--principalDark);
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
	background-color: var(--principalDark);
}
body .p-panelmenu .p-panelmenu-header.p-highlight > a:hover {
	border: 1px solid var(--principal);
	background-color: var(--principal);
}

body a:hover {
	color: var(--principal);
}
body .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
	background-color: var(--principal);
}
body .p-fileupload-choose:not(.p-disabled):hover {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-button:enabled:hover {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-togglebutton.p-highlight:hover {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .p-button.p-button-info:enabled:hover,
body .p-buttonset.p-button-info > .p-button:enabled:hover {
	background-color: var(--principal);
	border-color: var(--principal);
}
body .fc .fc-event {
	background-color: var(--principal);
	border: 1px solid var(--principal);
}
body .fc .fc-toolbar .p-button:enabled:hover {
	background-color: var(--principal);
	border-color: var(--principal);
}
.reactSelect__control {
	box-shadow: 0 0 0 0 var(--principal) !important;
}
.reactSelect__control:hover {
	border-color: var(--principal) !important;
	box-shadow: 0 0 0 0 var(--principal) !important;
}

body .p-button.p-button-info:enabled:focus,
body .p-buttonset.p-button-info > .p-button:enabled:focus {
	background-color: var(--principal);
}

body .p-button-primary:enabled:focus {
	background-color: var(--principalDark);
}
body .p-button-cancel {
	background-color: var(--vermelho);
	border: 1px solid var(--vermelho);
}
body .p-button-cancel:enabled:focus {
	background-color: var(--vermelhoDark);
	box-shadow: 0 0 0 0.1em var(--vermelhoDark);
}
body .p-button-cancel:enabled:active {
	background-color: var(--vermelhoDark);
	border-color: var(--vermelhoDark);
}
body .p-button-danger {
	background-color: var(--vermelho);
	border: 1px solid var(--vermelho);
}
body .p-button-danger:focus {
	background-color: var(--vermelhoDark) !important;
	border: 1px solid var(--vermelhoDark) !important;
}
body .p-button-danger:hover {
	background-color: var(--vermelhoDark) !important;
	border: 1px solid var(--vermelhoDark) !important;
}
body .p-button-danger:enabled:active {
	background-color: var(--vermelhoDark) !important;
	border-color: var(--vermelhoDark) !important;
}
body .p-button:enabled:focus {
	background-color: var(--principalDark);
	box-shadow: 0 0 0 0.1em var(--principal);
}
body .p-button:enabled:active {
	background-color: var(--principalDark);
	border-color: var(--principalDark);
}
body .p-button-success {
	background-color: var(--verdeButton);
	border: 1px solid var(--verdeButton);
}
body .p-button-success:focus {
	background-color: var(--verde) !important;
	border: 1px solid var(--verde) !important;
}
body .p-button-success:enabled:active {
	background-color: var(--verde) !important;
	border-color: var(--verde) !important;
}

.p-toolbar.color-template {
	background-color: var(--cinzaBackground);
}

.layout-main.color-template {
	background-color: var(--cinzaBackground);
}

.p-toast .p-toast-message.p-toast-message-info {
	border-color: var(--principal);
}
