.message-nota-compra-effect {
	animation: animate 1s ease-in-out;
}

@keyframes animate {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.015);
	}
	100% {
		transform: scale(1);
	}
}
