.altera-percentual-ou-valor {
    cursor: pointer;
    background-color: lightgrey;
    /* posicao */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /*posição ajuste */
    margin-left: -2px;
    width: 45px;
}

.altera-percentual-ou-valor .altera-percentual-ou-valor-text {
    display: inline-flex;
}

.altera-percentual-ou-valor .fa {
    display: none;
}

.altera-percentual-ou-valor:hover .altera-percentual-ou-valor-text {
    display: none;
}

.altera-percentual-ou-valor:hover .fa {
    display: inline-flex;
}