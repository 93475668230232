.table-totais tr:nth-child(odd) td {
	background-color: rgb(225, 225, 225);
}

.table-totais tr:nth-child(even) td {
	background-color: rgb(250, 250, 250);
}

.grid-totais table {
	color: #000;
}

@keyframes labelHover {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.02);
	}
	100% {
		transform: scale(1);
	}
}

.label-totais:hover {
	color: var(--principal);
	font-weight: 500;
	animation-name: labelHover;
	animation-duration: 0.4s;
	animation-timing-function: ease-out;
}
