@keyframes labelHover {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.02);
	}
	100% {
		transform: scale(1);
	}
}

.label-totais:hover {
	color: var(--principal);
	font-weight: 500;
	animation-name: labelHover;
	animation-duration: 0.4s;
	animation-timing-function: ease-out;
}
