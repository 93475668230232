.input-double-calculate-button {
	cursor: pointer;
	background-color: var(--principal);
	/* posicao */
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	/*posição ajuste */
	margin-left: -2px;
	width: 45px;
}
