@import url(https://fonts.googleapis.com/css?family=Ubuntu);

#telaManutencao {
	background: linear-gradient(to right, rgb(0, 25, 47) 0%, rgb(0, 96, 149) 100%);
	font-family: 'Ubuntu';
	height: 100%;
}

* {
	box-sizing: border-box;
}

.boxe {
	width: 450px;
	height: 100%;
	max-height: 600px;
	min-height: 450px;
	background: #186692;
	border-radius: 20px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 30px 50px;
}

.boxe .box__ghost {
	padding: 15px 25px 25px;
	position: absolute;
	left: 50%;
	top: 30%;
	transform: translate(-50%, -30%);
}

.boxe .box__ghost .symbol:nth-child(1) {
	opacity: 0.2;
	animation: shine 4s ease-in-out 3s infinite;
}

.boxe .box__ghost .symbol:nth-child(1):before,
.boxe .box__ghost .symbol:nth-child(1):after {
	content: '';
	width: 12px;
	height: 4px;
	background: var(--branco);
	position: absolute;
	border-radius: 5px;
	bottom: 65px;
	left: 0;
}

.boxe .box__ghost .symbol:nth-child(1):before {
	transform: rotate(45deg);
}

.boxe .box__ghost .symbol:nth-child(1):after {
	transform: rotate(-45deg);
}

.boxe .box__ghost .symbol:nth-child(2) {
	position: absolute;
	left: -5px;
	top: 30px;
	height: 18px;
	width: 18px;
	border: 4px solid;
	border-radius: 50%;
	border-color: var(--branco);
	opacity: 0.2;
	animation: shine 4s ease-in-out 1.3s infinite;
}

.boxe .box__ghost .symbol:nth-child(3) {
	opacity: 0.2;
	animation: shine 3s ease-in-out 0.5s infinite;
}

.boxe .box__ghost .symbol:nth-child(3):before,
.boxe .box__ghost .symbol:nth-child(3):after {
	content: '';
	width: 12px;
	height: 4px;
	background: var(--branco);
	position: absolute;
	border-radius: 5px;
	top: 5px;
	left: 40px;
}

.boxe .box__ghost .symbol:nth-child(3):before {
	transform: rotate(90deg);
}

.boxe .box__ghost .symbol:nth-child(3):after {
	transform: rotate(180deg);
}

.boxe .box__ghost .symbol:nth-child(4) {
	opacity: 0.2;
	animation: shine 6s ease-in-out 1.6s infinite;
}

.boxe .box__ghost .symbol:nth-child(4):before,
.boxe .box__ghost .symbol:nth-child(4):after {
	content: '';
	width: 15px;
	height: 4px;
	background: var(--branco);
	position: absolute;
	border-radius: 5px;
	top: 10px;
	right: 30px;
}

.boxe .box__ghost .symbol:nth-child(4):before {
	transform: rotate(45deg);
}

.boxe .box__ghost .symbol:nth-child(4):after {
	transform: rotate(-45deg);
}

.boxe .box__ghost .symbol:nth-child(5) {
	position: absolute;
	right: 5px;
	top: 40px;
	height: 12px;
	width: 12px;
	border: 3px solid;
	border-radius: 50%;
	border-color: var(--branco);
	opacity: 0.2;
	animation: shine 1.7s ease-in-out 7s infinite;
}

.boxe .box__ghost .symbol:nth-child(6) {
	opacity: 0.2;
	animation: shine 2s ease-in-out 6s infinite;
}

.boxe .box__ghost .symbol:nth-child(6):before,
.boxe .box__ghost .symbol:nth-child(6):after {
	content: '';
	width: 15px;
	height: 4px;
	background: var(--branco);
	position: absolute;
	border-radius: 5px;
	bottom: 65px;
	right: -5px;
}

.boxe .box__ghost .symbol:nth-child(6):before {
	transform: rotate(90deg);
}

.boxe .box__ghost .symbol:nth-child(6):after {
	transform: rotate(180deg);
}

.boxe .box__ghost .box__ghost-container {
	background: var(--branco);
	width: 117px;
	height: 124px;
	border-radius: 100px 100px 0 0;
	position: relative;
	margin: 0 auto;
	animation: upndown 3s ease-in-out infinite;
}

.boxe .box__ghost .box__ghost-container .box__ghost-eyes {
	position: absolute;
	left: 50%;
	top: 45%;
	height: 12px;
	width: 70px;
}

.boxe .box__ghost .box__ghost-container .box__ghost-eyes .box__eye-left {
	width: 12px;
	height: 12px;
	background: #332f63;
	border-radius: 50%;
	margin: 0 10px;
	position: absolute;
	left: 0;
}

.boxe .box__ghost .box__ghost-container .box__ghost-eyes .box__eye-right {
	width: 12px;
	height: 12px;
	background: #332f63;
	border-radius: 50%;
	margin: 0 10px;
	position: absolute;
	right: 0;
}

.boxe .box__ghost .box__ghost-container .box__ghost-bottom {
	display: flex;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
}

.boxe .box__ghost .box__ghost-container .box__ghost-bottom div {
	flex-grow: 1;
	position: relative;
	top: -10px;
	height: 20px;
	border-radius: 100%;
	background-color: var(--branco);
}

.boxe .box__ghost .box__ghost-container .box__ghost-bottom div:nth-child(2n) {
	top: -12px;
	margin: 0 -0px;
	border-top: 15px solid #332f63;
	background: transparent;
}

.boxe .box__ghost .box__ghost-shadow {
	height: 20px;
	box-shadow: 0 50px 15px 5px #003c60;
	border-radius: 50%;
	margin: 0 auto;
	animation: smallnbig 3s ease-in-out infinite;
}

.boxe .box__description {
	position: absolute;
	bottom: 100px;
	left: 50%;
	transform: translateX(-50%);
}

.boxe .box__description .box__description-container {
	color: var(--branco);
	text-align: center;
	width: 320px;
	font-size: 16px;
	margin: 0 auto;
}

.boxe .box__description .box__description-container .box__description-title {
	font-size: 30px;
	letter-spacing: 0.5px;
}

.boxe .box__description .box__description-container .box__description-text {
	color: #d6d6d8;
	line-height: 20px;
	margin-top: 20px;
	font-size: 20px;
}

.boxe .box__description .box__button {
	display: block;
	position: relative;
	background: #ff5e65;
	border: 1px solid transparent;
	border-radius: 50px;
	height: 50px;
	text-align: center;
	text-decoration: none;
	color: var(--branco);
	line-height: 50px;
	font-size: 18px;
	padding: 0 70px;
	white-space: nowrap;
	margin-top: 25px;
	transition: background 0.5s ease;
	overflow: hidden;
	-webkit-mask-image: -webkit-radial-gradient(white, black);
}

.boxe .box__description .box__button:before {
	content: '';
	position: absolute;
	width: 20px;
	height: 100px;
	background: var(--branco);
	bottom: -25px;
	left: 0;
	border: 2px solid var(--branco);
	transform: translateX(-50px) rotate(45deg);
	transition: transform 0.5s ease;
}

.boxe .box__description .box__button:hover {
	background: transparent;
	border-color: var(--branco);
}

.boxe .box__description .box__button:hover:before {
	transform: translateX(250px) rotate(45deg);
}

@keyframes upndown {
	0% {
		transform: translateY(5px);
	}
	50% {
		transform: translateY(15px);
	}
	100% {
		transform: translateY(5px);
	}
}

@keyframes smallnbig {
	0% {
		width: 90px;
	}
	50% {
		width: 100px;
	}
	100% {
		width: 90px;
	}
}

@keyframes shine {
	0% {
		opacity: 0.2;
	}
	25% {
		opacity: 0.1;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 0.2;
	}
}
