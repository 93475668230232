.listagem-contas-content {
	background-color: white;
	overflow: auto;
	max-height: 250px;
	height: 100%;
}

.listagem-contas-content::-webkit-scrollbar {
	width: 6px;
	background: #f4f4f4;
	cursor: pointer;
}

.listagem-contas-content::-webkit-scrollbar-track {
	background-color: #f4f4f4;
}

.listagem-contas-content::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #dad7d7;
}

#card-header-listagem-contas {
	padding: 14px;
	display: flex;
	justify-content: space-between;
}

#card-header-listagem-contas label {
	font-size: 17px;
	color: var(--cinza);
}

.divider-listagem-contas {
	border: none;
	height: 1px;
	margin: 0;
	flex-shrink: 0;
	background-color: rgba(0, 0, 0, 0.12);
}

.listagem-contas-coluna-valor {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
	padding: 0px;
}

.listagem-contas-coluna-total {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	padding: 0px;
}

.listagem-contas-coluna-valor:hover {
	text-decoration: underline;
	cursor: pointer;
}

.listagem-contas-title {
	display: block;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.87);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
