.card-itens-nfce .p-card-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.card-itens-nfce .p-card-footer {
	padding: 5px 0px 0px 0px;
}

.table-itens-nfce-empty .p-datatable-wrapper,
.table-itens-nfce-empty .p-datatable-table,
.table-itens-nfce-empty .p-datatable-emptymessage td {
	height: 100%;
	max-height: 100%;
}

.table-itens-nfce .p-datatable-thead {
	background-color: var(--branco);
	position: sticky;
	top: 0;
	z-index: 1;
}

.table-itens-nfce {
	max-height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}

.table-itens-nfce::-webkit-scrollbar-track {
	background: none;
}

.table-itens-nfce::-webkit-scrollbar-thumb {
	background-color: rgba(177, 177, 177, 0.74);
	border-radius: 20px;
	border: none;
}
