.modal-importar-modelo-ncm::-webkit-scrollbar {
  width: 0.4rem;
}

.modal-importar-modelo-ncm::-webkit-scrollbar-track {
  background: none; 
}

.modal-importar-modelo-ncm::-webkit-scrollbar-thumb {
  background-color: rgba(177, 177, 177, 0.74);  
  border-radius: 20px;       
  border: none; 
}