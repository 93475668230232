.tab-view-cte-pendente .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--azul) !important;
	border-bottom: 4px solid var(--azul) !important;
}

.tab-view-cte-aguardando .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--cinza) !important;
	border-bottom: 4px solid var(--cinza) !important;
}

.tab-view-cte-transmitido .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--verde) !important;
	border-bottom: 4px solid var(--verde) !important;
}

.tab-view-cte-rejeitado .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--amarelo) !important;
	border-bottom: 4px solid var(--amarelo) !important;
}

.tab-view-cte-cancelado .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--vermelho) !important;
	border-bottom: 4px solid var(--vermelho) !important;
}

.input-double-valor-total-pendente {
	font-weight: bold;
	color: var(--azul);
}

.input-double-valor-total-aguardando {
	font-weight: bold;
	color: var(--cinza);
}

.input-double-valor-total-transmitido {
	font-weight: bold;
	color: var(--verde);
}

.input-double-valor-total-rejeitado {
	font-weight: bold;
	color: var(--amarelo);
}

.input-double-valor-total-cancelado {
	font-weight: bold;
	color: var(--vermelho);
}
