.modal {
	margin-bottom: unset !important;
}

.modal h1 {
	margin: 0 !important;
	border-bottom: 1px solid #d5d5d5 !important;
	padding: 0px;
	font-size: 24px;
}
