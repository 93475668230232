#button-input-copy-clicked{
  animation:animate-button 1s ease-in-out !important;
}

@keyframes animate-button{
  0%{
    transform: scale(1.0)
  }
  50%{
    transform: scale(1.15)    
  }
  100%{
    transform: scale(1.0)    
  }
}