#divider-documentos-assinatura-periodo {
	border: none;
	height: 1px;
	margin: 0;
	flex-shrink: 0;
	background-color: rgba(0, 0, 0, 0.12);
}

#documentos-periodo {
	background-color: white;
	overflow: auto;
	max-height: 280px;
}

#documento-identificacao-title {
	display: block;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.87);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#documento-pessoa {
	display: block;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.87);
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
}

#card-header-documentos-assinados-periodo {
	padding: 14px;
	display: flex;
	justify-content: space-between;
}

#card-header-documentos-assinados-periodo label {
	font-size: 17px;
	color: var(--cinza);
}

.documento-pessoa-assinado-periodo {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}
