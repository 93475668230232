.tab-view-mdfe-pendente .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--azul) !important;
	border-bottom: 4px solid var(--azul) !important;
}

.tab-view-mdfe-aguardando .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--cinza) !important;
	border-bottom: 4px solid var(--cinza) !important;
}

.tab-view-mdfe-transmitido .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--verde) !important;
	border-bottom: 4px solid var(--verde) !important;
}

.tab-view-mdfe-encerrado .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--cinza) !important;
	border-bottom: 4px solid var(--cinza) !important;
}

.tab-view-mdfe-rejeitado .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--amarelo) !important;
	border-bottom: 4px solid var(--amarelo) !important;
}

.tab-view-mdfe-cancelado .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--vermelho) !important;
	border-bottom: 4px solid var(--vermelho) !important;
}

.tab-view-mdfe-denegado .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	color: var(--vermelho) !important;
	border-bottom: 4px solid var(--vermelho) !important;
}

.tables-tabs-mdfe {
	margin: 0 !important;
	padding: 0px 8px !important;
}

.cards-tabs-mdfe {
	border-radius: 3px;
	margin: 8px;
	box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.23);
	min-height: 200px;
	max-height: 600px;
	overflow-y: auto;
}

.input-correction {
	width: 100%;
}
