#previsoes-receitas-content {
	background-color: white;
	overflow: auto;
	max-height: 280px;
}

#card-header-previsoes-receitas {
	padding: 14px;
	display: flex;
	justify-content: space-between;
}

#card-header-previsoes-receitas label {
	font-size: 17px;
	color: var(--cinza);
}

#previsoes-receitas-button-content {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

#previsoes-receitas-button-content button {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin: 2px;
}

#divider-previsoes-receitas {
	border: none;
	height: 1px;
	margin: 0;
	flex-shrink: 0;
	background-color: rgba(0, 0, 0, 0.12);
}

#previsoes-receitas-coluna-data {
	margin: 0px;
	color: rgba(0, 0, 0, 0.77);
	font-size: 14px;
	font-weight: 400;
}

#previsoes-receitas-coluna-valor {
	display: flex;
	justify-content: flex-end;
	font-size: 15px;
	font-weight: 600;
	color: #36a536;
	padding: 0px;
}

#previsoes-receitas-title {
	display: block;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.87);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
