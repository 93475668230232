.buttonAlterarStyle {
	color: var(--principal);
	font-weight: bold;
	cursor: pointer;
}

.buttonRemoverCartaoStyle {
	color: #e91224;
	font-weight: bold;
	cursor: pointer;
}

.helpTooltipCardStyle {
	padding-top: 5px;
}

.cardStyle {
	height: 12rem;
	display: flex;
	width: 100%;
	flex-direction: column;
	margin: -16px 0;
}

.card-planos .p-card-title {
	font-size: 1.2rem;
}
