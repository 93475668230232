.dashboard-scroll::-webkit-scrollbar {
	width: 6px;
	background: #f4f4f4;
	cursor: pointer;
}

.dashboard-scroll::-webkit-scrollbar-track {
	background-color: #f4f4f4;
}

.dashboard-scroll::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #dad7d7;
}

.tab-view-dashboard .p-tabview-title {
	color: var(--cinzaDark);
}

.tab-view-dashboard .p-tabview-nav.p-reset {
	display: flex;
	flex-flow: row nowrap;
}
/* https://stackoverflow.com/questions/34184535/change-justify-content-value-when-flex-items-overflow-container */
.tab-view-dashboard .p-tabview-nav.p-reset::before,
.tab-view-dashboard .p-tabview-nav.p-reset::after {
	content: '';
	margin: auto;
}

.tab-view-dashboard .p-tabview-nav li.p-highlight a {
	background-color: transparent !important;
	border-bottom: 2px solid var(--principal) !important;
	color: var(--principal) !important;
}

.tab-view-dashboard .p-tabview-nav li.p-highlight a span.p-tabview-title {
	color: var(--principal);
}

.tab-view-dashboard .p-tabview-nav li a {
	border: none !important;
	background-color: transparent !important;
}

.tab-view-dashboard .p-tabview-nav li {
	background-color: transparent !important;
}

.tab-view-dashboard .p-tabview-panels {
	border: none !important;
	background-color: var(--cinzaBackground) !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.tab-view-dashboard ul {
	overflow-y: hidden;
}

@media only screen and (max-width: 320px) {
	.tab-view-dashboard ul {
		overflow-y: hidden;
		padding-left: 2rem;
	}
}

.tab-view-dashboard ul::-webkit-scrollbar {
	height: 5px;
	background: #f4f4f4;
	cursor: pointer;
}

.tab-view-dashboard ul::-webkit-scrollbar-track {
	background-color: var(--cinzaBackground);
}

.tab-view-dashboard ul::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #dad7d7;
}
