.card-resultado-financeiro-dashboard {
	padding: 5px;
	border-radius: 5px;
	background-color: white;
	box-shadow:
		#00000033 0px 1px 3px 0px,
		#00000024 0px 1px 1px 0px,
		#0000001f 0px 2px 1px -1px;
}

.card-resultado-financeiro-dashboard-titulo {
	display: flex;
	justify-content: space-between;
}

.card-resultado-financeiro-dashboard-titulo-descricao {
	color: var(--cinza);
	font-size: 17px;
	padding-left: 5px;
	padding-right: 15px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.card-resultado-financeiro-dashboard-content {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.card-resultado-financeiro-dashboard-icone {
	padding: 5px;
	display: flex;
	align-items: center;
}

.card-resultado-financeiro-dashboard-valor {
	width: 100%;
	padding: 5px;
}

.card-resultado-financeiro-dashboard-valor-principal {
	text-align: end;
	font-size: 21px;
	font-weight: 500;
}

.card-resultado-financeiro-dashboard-valor-secundario {
	display: flex;
	justify-content: flex-end;
}

.card-resultado-financeiro-dashboard-valor-secundario-efetuado {
	text-align: end;
	padding-right: 10px;
}

.card-resultado-financeiro-dashboard-valor-secundario-efetuado label {
	color: var(--cinza);
	font-size: 13px;
}

.card-resultado-financeiro-dashboard-valor-secundario-efetuado div {
	font-size: 13px;
	color: #666666;
	font-weight: 600;
}

.card-resultado-financeiro-dashboard-valor-secundario-pendente {
	text-align: end;
}

.card-resultado-financeiro-dashboard-valor-secundario-pendente label {
	color: var(--cinza);
	font-size: 13px;
}

.card-resultado-financeiro-dashboard-valor-secundario-pendente div {
	font-size: 13px;
	color: #666666;
	font-weight: 600;
}
