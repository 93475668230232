.input-double-valor-total-pendente {
	font-weight: bold;
	color: var(--azul);
}

.input-double-valor-total-aguardando {
	font-weight: bold;
	color: var(--cinza);
}

.input-double-valor-total-transmitido {
	font-weight: bold;
	color: var(--verde);
}

.input-double-valor-total-encerrado {
	font-weight: bold;
	color: var(--cinza);
}

.input-double-valor-total-rejeitado {
	font-weight: bold;
	color: var(--amarelo);
}

.input-double-valor-total-cancelado {
	font-weight: bold;
	color: var(--vermelho);
}

.input-double-valor-total-denegado {
	font-weight: bold;
	color: var(--vermelho);
}
