.altera-cnpj-ou-cpf {
    cursor: pointer;
    background-color: lightgrey;
    /* posicao */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /*posição ajuste */
    margin-left: -2px;
    width: 55px;
}

.altera-cnpj-ou-cpf .altera-cnpj-ou-cpf-text {
    display: inline-flex;
}

.altera-cnpj-ou-cpf .fa {
    display: none;
}

.altera-cnpj-ou-cpf:hover .altera-cnpj-ou-cpf-text {
    display: none;
}

.altera-cnpj-ou-cpf:hover .fa {
    display: inline-flex;
}